import React from 'react'
import user1 from './images/user1.png'
import user2 from './images/user2.png'
import user3 from './images/user3.png'
import user4 from './images/user4.png'
import user5 from './images/user5.png'
import './Testimonials.css'

import Container from '@mui/material/Container';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';






const Testimonials = () => {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };
    const items = [
        <div className='card'>
            <img src={user1} alt='user1' />
            <p>
                A leader takes the lead to stand up and guide others in taking action. Ibtehaj Khan is a passionate and trustworthy Leader  </p>
            <p><span>Ibtehaj Khan</span></p>
            <p>Founder</p>
        </div>,
        <div className='card'>
            <img src={user2} alt='user2' />
            <p> Co-Founder of "The Botss" and Senior Data Engineer ,  Muhammad Anas Arshad has demonstrated a high amount of integrity in his goal. </p>
            <p><span>Anas Arshad</span></p>
            <p>Co-Founder</p>
        </div>,
        <div className='card'>
            <img src={user3} alt='user3' />
            <p>In just very short period of time, M.Razeen Bari has managed to find the various tech solutions. Punctual and well informed .
            </p>
            <p><span>Razeen Bari</span></p>
            <p>Jr. Machine Learning Engineer</p>
        </div>,
        <div className='card'>
            <img src={user4} alt='user4' />
            <p>A professional web developer, who is true to her word and performs really well. </p>
            <p><span>Dua Jan Muhammed</span></p>
            <p>Web Developer</p>
        </div>,
        <div className='card'>
            <img src={user5} alt='user5' />
            <p>Asim jaffry has managed to gain expertise in Data Analysis. A quick Learner and a hardworking employee.</p>
            <p><span>Asim Jaffry</span></p>
            <p>Jr.Data Analyst</p>
        </div>,
    ];
    return (
        <Container maxWidth="lg" className="testimonials">

            <h2>Team </h2>
            <span className='line'></span>


            <AliceCarousel mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                // disableDotsControls
                disableButtonsControls
                autoPlay


                autoPlayInterval={3500}
                animationDuration={1000}

                infinite />

      


        </Container >
    )
}

export default Testimonials
