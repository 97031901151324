
import React from 'react'
import './Advisory.css'
import  { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import pic from "./images/hero.jpg"

function Advisory() {
    return (
        <>
    <div class="margin"></div>

<section class="parallax">
	<div class="parallax-inner">
    <Carousel animationHandler="fade" swipeable={false}>
    <div key="slide1" style={{ padding: 20, height: 150 }}>
            Text 01
        </div>
        <div key="slide2" style={{ padding: 20, height: 150 }}>
            Text 02
        </div>
            </Carousel>
	</div>
</section>

<div class="margin"></div>
</>
    );

}

export default Advisory


