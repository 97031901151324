import React from 'react'
import './Footer.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <ul>
                    <li className='nav-item'>
                        <a href='https://www.linkedin.com/company/aithebotss' target="_blank"><LinkedInIcon/></a>
                    </li>
                    <li className='nav-item'>
                        <a href='https://www.facebook.com/ai.thebotss' target="_blank"><FacebookIcon/></a>
                    </li>
                    <li className='nav-item'>
                        <a href='https://www.instagram.com/ai.thebotss/' target="_blank"><InstagramIcon/></a>
                    </li>
                   
                </ul>
                <div className='bottom'>
                    <span className='line'></span>
                    <p>© 2022. All rights reserved by PROXIMA PK.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
