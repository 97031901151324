import React from 'react'
import './Demo.css'

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const Demo = () => {
    return (
        <Container maxWidth="lg" className="demo" id="demo">

            <Grid container spacing={4} mt={4}>
                <Grid item xs={12} sm={12} md={6}
                >
                    <Grid container  >
                        <Grid item xs={12} sm={12} md={12}
                        ><p className='one'>We Automate The World</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}
                        > <p className='two'>AI Revolution</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}
                        ><p className='three'>Reduce uncertianity, predict and define outcomes, automate complex Process and optimize your expertise.</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}
                        > <button className='button'>Reach Our Experts</button>
                        </Grid>
                    </Grid>




                </Grid>
                <Grid item xs={12} sm={12} md={6}
                    style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>

                    <iframe src="https://www.youtube.com/embed/6qJkDwuFzaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </Grid>
            </Grid>
        </Container>

    )
}

export default Demo
