import React from 'react'


import "./OurSevices.css"
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import Fade from 'react-reveal/Fade';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import ChatBot from "./images/chatbot.png"
import Data from "./images/Data.png"
import Ai from "./images/AI.png"

function OurSevices() {


    return (
        <div className='OurServices' id="Services">
            <Container maxWidth="lg" >
                <h2>Our Services </h2>
                <span className='line'></span>

                <Grid container spacing={2} mt={4}>
                    <Grid item xs={12} sm={12} md={4}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Fade bottom>
                            <Card className='Card' sx={{ maxWidth: 300, Height: 100 }} >
                                <CardMedia
                                    component="img"
                                    height="140"

                                    image={ChatBot}
                                    alt="ChatBot"
                                />
                                <CardContent>


                                    <Typography variant="h5" sx={{ mb: .5 }} color="text.secondary">
                                        ChatBot
                                    </Typography>
                                    <span className='line2'></span>
                                    <Typography variant="body2">
                                        Grow your buisiness reach and boost your Sales with Chatbots.
                                        <br />

                                    </Typography>
                                </CardContent>

                            </Card>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Fade bottom>
                            <Card className='Card' sx={{ maxWidth: 300, Height: 100 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={Ai}
                                    alt="Ai"
                                />
                                <CardContent>


                                    <Typography variant="h5" sx={{ mb: .5 }} color="text.secondary">
                                        Conversational AI
                                    </Typography>
                                    <span className='line2'></span>
                                    <Typography variant="body2">
                                        Technology that enables better conversation with your customer.
                                        {/* by building a high-performance and sustainable future on cloud. */}
                                        <br />

                                    </Typography>
                                </CardContent>

                            </Card>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Fade bottom>
                            <Card className='Card' sx={{ maxWidth: 300, Height: 100 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={Data}
                                    alt="Data"
                                />
                                <CardContent>


                                    <Typography variant="h5" sx={{ mb: .5 }} color="text.secondary">
                                        Chatbot Analytics
                                    </Typography>
                                    <span className='line2'></span>
                                    <Typography variant="body2">
                                        Get Chatbot insights and make informed decisions for your business growth.

                                        <br />

                                    </Typography>
                                </CardContent>

                            </Card>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>

    )
}

export default OurSevices