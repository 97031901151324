import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from './images/logo.png'
import logo2 from './images/logo2.png'


import './Navbar.css'

const Navbar = () => {
    const [color, setColor] = useState(false)

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)
    
    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <div className={color ? 'header ' : ' header header-bg'}>


            <nav className='navbar'>
                <div className='one'> <a href='/' className='logo'>
                    {color ? <img src={logo} alt='logo' />:
                   <div className='flexx'> <img className='image2' src={logo2} alt='logo' /><p className='Heading1'> THE Botss</p></div>}
                   
                </a>
                </div>
                <div className='two'>
                    <ul className={click ? "nav-menu active " : "nav-menu "}>
                        <li className='nav-item'>
                        
                            <a href='/' onClick={closeMenu}>Home</a>
                        </li>
                        <li className='nav-item'>
                            <a href='#about' onClick={closeMenu}>About</a>
                        </li>
                        <li className='nav-item'>
                            <a href='#Services' onClick={closeMenu}>Our Services</a>
                        </li>
                        <li className='nav-item'>
                            <a href='#demo' onClick={closeMenu}>Solution</a>
                        </li>
                    </ul>
                </div>
                <div className='three'>
                    <div className="NavButton" >
                        <a target="_blank" href="https://wa.me/+923101062596"><button className='button-17'>Contact Us</button></a>
                        
                    </div>
                    <div className='hamburger' onClick={handleClick}>
                        {click ? (<FaTimes size={30} style={{ color: 'black' }} />)
                            : (<FaBars size={30} style={{ color: "black" }} />)}

                    </div>
                </div>







            </nav>
        </div>
    )
}

export default Navbar
