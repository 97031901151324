import React from 'react'
import PIBT from "./images/PIBT.jpg"
import SMIU from "./images/SMIU.png"
import NIC from "./images/NIC.png"
import Proxima from "./images/PROXIMA.png"
import "./OurPartners.css"
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';


import Fade from 'react-reveal/Fade';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';







function OurPartners() {
  return (
    <>
      <div className='ourPartnerss'>

        <h2>Affliated With </h2>

        <span className='line'></span>

        <Container maxWidth="lg"

        >
          <Grid container spacing={4} 

          >
            <Grid item xs={12} sm={6} md={3}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Fade bottom>
                <a href='https://pitb.gov.pk/' target="_blank" >
                  <CardActionArea>
                    <Card className='Card' sx={{ maxWidth: 345 }} >

                      <CardMedia
                        component="img"
                        height="140"
                        image={PIBT}
                        alt="green iguana"
                      />

                    </Card>
                  </CardActionArea>
                </a>

              </Fade>
            </Grid>
            <Grid item xs={12} sm={6} md={3}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Fade bottom>
                <a href="https://smiu.edu.pk/" target="_blank">
                  <CardActionArea>
                    <Card className='Card' sx={{ maxWidth: 345 }} >

                      <CardMedia
                        component="img"
                        height="140"
                        image={SMIU}
                        alt="green iguana"
                      />



                    </Card>
                  </CardActionArea>
                </a>

              </Fade>
            </Grid>
            <Grid item xs={12} sm={6} md={3}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Fade bottom>
                <a href="http://nep.pitb.gov.pk/" target="_blank" rel="noopener noreferrer">
                  <CardActionArea>
                    <Card className='Card' sx={{ maxWidth: 345 }} >

                      <CardMedia
                        component="img"
                        height="140"
                        image={NIC}
                        alt="green iguana"
                      />


                    </Card>
                  </CardActionArea>
                </a>

              </Fade>
            </Grid>

            <Grid item xs={12} sm={6} md={3}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Fade bottom>
               <a href="http://proxima.pk/" target="_blank" rel="noopener noreferrer">
               <CardActionArea>
                  <Card className='Card' sx={{ maxWidth: 345 }} >

                    <CardMedia
                      component="img"
                      height="140"
                      image={Proxima}
                      alt="green iguana"
                    />

                  </Card>
                </CardActionArea>
               </a>
                
              </Fade>
            </Grid>





          </Grid>




        </Container>




      </div >
    </>
  )
}



export default OurPartners