import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import WhoAreWe from "./components/WhoAreWe"
import OurPartners from "./components/OurPartners"
import Testimonials from './components/Testimonials'
import OurSevices from './components/OurSevices'
import ContactUs from './components/ContactUs'
import Demo from './components/Demo'
import SeeWhat from"./components/SeeWhat"
import Footer from './components/Footer'
import Blogs from "./components/Blogs"
import Advisory from './components/Advisory'
import { ParallaxProvider } from 'react-scroll-parallax'

function App() {
  return (
    <ParallaxProvider>
      <div>

<Navbar />
<Hero />
<WhoAreWe />
{/* <Advisory/> */}
<OurPartners />
<SeeWhat/>
<OurSevices />
<Demo />

<Testimonials />
{/* <Blogs /> */}



< ContactUs/>
<Footer />
</div>
      </ParallaxProvider>
    
  );
}

export default App;
