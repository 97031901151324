import React from 'react'
import "./SeeWhat.css"

function SeeWhat() {
    return (

        <div className='SeeWhat'>
            <div className='container'>
                <h3>See What "The Botss" can do for you</h3>

                <div className='bottom'>
                    <span className='line'></span>
                    <p>Expand the reach of your advertising and unlock new revenue with AI-powered targeting</p>
                </div>
                <div className="DemoButton" >
                    <button className='button-17'>Get Demo</button>
                </div>
            </div>
        </div>
    )
}

export default SeeWhat