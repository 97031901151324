
import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Bot from "./images/Bot.png"
import "./ContactUs.css"



const theme = createTheme();

export default function ContactUs() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>

            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >

              <Typography component="h1" variant="h5">
                Contact Us
              </Typography>
              <span className='line'></span>
              <Box component="form" action="https://formsubmit.co/ai.thebotss@gmail.com" method="POST" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"

                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="Phone No"
                      label="Phone No"
                      name="Phone No"
                      autoComplete="family-name"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="Description"
                      label="Description"
                    
                      id="Description"
                      autoComplete="Description"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <button className='button' type="submit" fullWidth>Submit</button>
                  </Grid>

                </Grid>



              </Box>
            </Box>


          </Grid>

          <Grid item xs={12} sm={12} md={6}>

            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img className="BotImage" src={Bot} alt="" />
            </Box>






          </Grid>

        </Grid>
      </Container>

    </ThemeProvider>
  );
}
