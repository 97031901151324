import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero'>
            <div className='content'>
                <p></p>
                <p></p>
                <p>The Botss</p>
                <p>We Automate The World</p>
                <button href='/' className='button'>Pay Less for More</button>
            </div>
        </div>
    )
}

export default Hero
