import React from 'react'
import "./WhoAreWe.css"
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import Fade from 'react-reveal/Fade';
import Container from '@mui/material/Container';


function WhoAreWe() {
    return (
        <Container maxWidth="lg" className="WHO" id="about">
            
                <h2>Who We Are </h2>
                <span className='line2'></span>
                
                    <Grid container spacing={2}>
                        <Grid item p={2} xs={12}sm={12} md={12}>
                            <p className='INTRO'> The botss is a start up company working towards automation, Artificial Intelligence, Chatbots and Conversational Ai.
                                The Botss with an innate desire to bring difference in the approach towards understanding the Ai and it's working, would be providing the tech solution of  various industrial problem through Automation.

                                </p>
                        </Grid>

                    </Grid>
                    <Grid container spacing={4} mt={4}>
                        <Grid item xs={12} md={4}>
                        <Fade bottom>
                            <Card className='Card' sx={{ minWidth: 240 , Height:100}} >
                                <CardContent>
                                   

                                    <Typography variant="h5" sx={{ mb: .5  }} color="text.secondary">
                                     Quantity
                                    </Typography>
                                    <span className='line2'></span>
                                    <Typography variant="body2">
                                     High quality is the key to high-perfoming models and speed to ROI from dat solution.
                                        <br />
                                       
                                    </Typography>
                                </CardContent>
                               
                            </Card>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <Fade bottom>
                            <Card className='Card' sx={{ minWidth: 240 , Height:100}}>
                                <CardContent>
                                   

                                    <Typography variant="h5" sx={{ mb: .5}} color="text.secondary">
                                    Scale
                                    </Typography>
                                    <span className='line2'></span>
                                    <Typography variant="body2">
                                      Innovate and accelerate your digital buisiness transformation 
                                      {/* by building a high-performance and sustainable future on cloud. */}
                                        <br />
                                       
                                    </Typography>
                                </CardContent>
                               
                            </Card>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <Fade bottom>
                            <Card className='Card' sx={{ minWidth: 240,  Height:100 }}>
                                <CardContent>
                                   

                                    <Typography variant="h5" sx={{ mb: .5}} color="text.secondary">
                                    Speed
                                    </Typography>
                                    <span className='line2'></span>
                                    <Typography variant="body2">
                                      Effectly Integrate high-quality and high-precision AI solution into your organization 
                                      {/* to solve complex buissness Problem and acheive Faster Result */}
                                        <br />
                                       
                                    </Typography>
                                </CardContent>
                               
                            </Card>
                            </Fade>
                        </Grid>
                    </Grid>

         
       
      </Container>
        
    )
}

export default WhoAreWe




